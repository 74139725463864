import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { Link, graphql } from "gatsby";
import "../templates/pagination.css";

const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <div>
    <div className="back-button">
      <Link to="/">← Back to Home</Link>
    </div>
    <Helmet title={title} />
    <div className="all-tags">
      <h1>All Tags</h1>
      <div>
        {group
          .filter((tag) => tag.fieldValue !== "resume" && tag.fieldValue !== "private")
          .map((tag) => (
            <div style={{ marginBottom: "1rem" }}>
              <Link to={`/tags/${tag.fieldValue}/`}>
                <span className="all-tags-item">#{tag.fieldValue}</span> ({tag.totalCount})
              </Link>
            </div>
          ))}
      </div>
    </div>
  </div>
);

TagsPage.propTypes = {
  data: PropTypes.shape({
    allMarkdownRemark: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          fieldValue: PropTypes.string.isRequired,
          totalCount: PropTypes.number.isRequired,
        }).isRequired
      ),
    }),
    site: PropTypes.shape({
      siteMetadata: PropTypes.shape({
        title: PropTypes.string.isRequired,
      }),
    }),
  }),
};
export default TagsPage;
export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(filter: { fileAbsolutePath: { regex: "/(posts)/" }}) {
      group(field: frontmatter___tag) {
        fieldValue
        totalCount
      }
    }
  }
`;
